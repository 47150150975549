export type Links = {
	name: string
	href: string
	done: boolean
	external?: boolean
	pro?: boolean
	children?: Links
}[]

export const navigation: Links = [
	{
		name: 'Getting Started',
		href: '/docs/getting-started',
		done: false,
		children: [
			{ name: 'Introduction', href: '/docs/getting-started/introduction', done: false },
			{ name: 'Installation', href: '/docs/getting-started/installation', done: false },
			{ name: 'Change log', href: '/docs/getting-started/change-log', done: false },
			{ name: 'Roadmap', href: '/docs/getting-started/roadmap', done: false },
		],
	},
	{
		name: 'Components',
		href: '/docs/components',
		done: false,
		children: [
			{
				name: 'Layout',
				href: '/docs/components/layout',
				done: false,
				children: [
					{ name: 'Container', href: '/docs/components/layout/container', done: true },
					{ name: 'Flex', href: '/docs/components/layout/flex', done: true },
					{ name: 'Grid', href: '/docs/components/layout/grid', done: true },
					{ name: 'Sticky', href: '/docs/components/layout/sticky', done: true },
					{ name: 'Separator', href: '/docs/components/layout/separator', done: true },
					{ name: 'Aspect Ratio', href: '/docs/components/layout/aspect-ratio', done: true },
					{ name: 'Visually Hidden', href: '/docs/components/layout/visually-hidden', done: true },
				],
			},
			{
				name: 'Typography',
				href: '/docs/components/typography',
				done: false,
				children: [
					{ name: 'Eyebrow', href: '/docs/components/typography/eyebrow', done: true },
					{ name: 'H1', href: '/docs/components/typography/h1', done: true },
					{ name: 'H2', href: '/docs/components/typography/h2', done: true },
					{ name: 'H3', href: '/docs/components/typography/h3', done: true },
					{ name: 'H4', href: '/docs/components/typography/h4', done: true },
					{ name: 'H5', href: '/docs/components/typography/h5', done: true },
					{ name: 'H6', href: '/docs/components/typography/h6', done: true },
					{ name: 'Lead', href: '/docs/components/typography/lead', done: true },
					{ name: 'P', href: '/docs/components/typography/p', done: true },
					{ name: 'Span', href: '/docs/components/typography/span', done: true },
					{ name: 'Em', href: '/docs/components/typography/em', done: true },
					{ name: 'I', href: '/docs/components/typography/i', done: true },
					{ name: 'Strong', href: '/docs/components/typography/strong', done: true },
					{ name: 'B', href: '/docs/components/typography/b', done: true },
					{ name: 'Q', href: '/docs/components/typography/q', done: true },
					{ name: 'Blockquote', href: '/docs/components/typography/blockquote', done: true },
					{ name: 'Ul', href: '/docs/components/typography/ul', done: true },
					{ name: 'Ol', href: '/docs/components/typography/ol', done: true },
					{ name: 'Kbd', href: '/docs/components/typography/kbd', done: true },
					{ name: 'Code', href: '/docs/components/typography/code', done: true },
				],
			},
			{
				name: 'Media',
				href: '/docs/components/media',
				done: false,
				children: [
					{ name: 'Icon', href: '/docs/components/media/icon', done: true },
					{ name: 'Img', href: '/docs/components/media/img', done: true },
					{ name: 'Video', href: '/docs/components/media/video', done: false, pro: true },
					{ name: 'Audio', href: '/docs/components/media/audio', done: false, pro: true },
					{ name: 'Avatar', href: '/docs/components/media/avatar', done: true },
				],
			},
			{
				name: 'Navigation',
				href: '/docs/components/navigation',
				done: false,
				children: [
					{ name: 'Link', href: '/docs/components/navigation/link', done: false },
					{ name: 'Navigation Menu', href: '/docs/components/navigation/navigation-menu', done: false },
					{ name: 'Breadcrumbs', href: '/docs/components/navigation/breadcrumbs', done: false },
					{ name: 'Pagination', href: '/docs/components/navigation/pagination', done: false },
					{ name: 'Stepper', href: '/docs/components/navigation/stepper', done: false },
				],
			},
			{
				name: 'Buttons',
				href: '/docs/components/buttons',
				done: false,
				children: [
					{ name: 'Button', href: '/docs/components/buttons/button', done: false },
					{ name: 'Burger Button', href: '/docs/components/buttons/burger-button', done: false },
					{ name: 'Button Group', href: '/docs/components/buttons/button-group', done: false },
				],
			},
			{
				name: 'Overlays',
				href: '/docs/components/overlays',
				done: false,
				children: [
					{ name: 'Dialog', href: '/docs/components/overlays/dialog', done: true },
					{ name: 'Popover', href: '/docs/components/overlays/popover', done: false },
					{ name: 'Tooltip', href: '/docs/components/overlays/tooltip', done: true },
					{ name: 'Dropdown Menu', href: '/docs/components/modals/dropdown-menu', done: false },
					{ name: 'Context Menu', href: '/docs/components/overlays/context-menu', done: false },
					{ name: 'Hover Card', href: '/docs/components/overlays/hover-card', done: false },
				],
			},
			{
				name: 'Disclosure',
				href: '/docs/components/disclosure',
				done: false,
				children: [
					{ name: 'Collapsible', href: '/docs/components/disclosure/collapsible', done: false },
					{ name: 'Accordion', href: '/docs/components/disclosure/accordion', done: false },
					{ name: 'Tabs', href: '/docs/components/disclosure/tabs', done: false },
				],
			},
			{
				name: 'Notifications',
				href: '/docs/components/notifications',
				done: false,
				children: [
					{ name: 'Notification', href: '/docs/components/notifications/notification', done: false },
					{ name: 'Notification Panel', href: '/docs/components/notifications/notification-panel', done: false },
					{ name: 'Toast', href: '/docs/components/notifications/toast', done: false },
					{ name: 'Alert', href: '/docs/components/notifications/alert', done: false },
				],
			},
			{
				name: 'Status',
				href: '/docs/components/status',
				done: false,
				children: [
					{ name: 'Spinner', href: '/docs/components/status/spinner', done: false },
					{ name: 'Progress Bar', href: '/docs/components/status/progress-bar', done: false },
				],
			},
			{
				name: 'Data Display',
				href: '/docs/components/data-display',
				done: false,
				children: [
					{ name: 'Article Preview', href: '/docs/components/data-display/article-preview', done: false },
					{ name: 'Badge', href: '/docs/components/data-display/badge', done: false },
					{ name: 'Card', href: '/docs/components/data-display/card', done: false },
					{ name: 'Command Line', href: '/docs/components/data-display/command-line', done: false },
					{ name: 'Table', href: '/docs/components/data-display/table', done: false },
					{ name: 'Tag', href: '/docs/components/data-display/tag', done: false },
					{ name: 'Tag Group', href: '/docs/components/data-display/tag-group', done: false },
					{ name: 'Tag Picker', href: '/docs/components/data-display/tag-picker', done: false },
					{ name: 'Testimonial', href: '/docs/components/data-display/testimonial', done: false },
					{ name: 'Product Tile', href: '/docs/components/data-display/product-tile', done: false },
					{ name: 'Skeleton', href: '/docs/components/data-display/skeleton', done: false },
					{ name: 'Statistic', href: '/docs/components/data-display/statistic', done: false },
				],
			},
			{
				name: 'Data Entry',
				href: '/docs/components/data-entry',
				done: false,
				children: [
					{ name: 'Address Input', href: '/docs/components/data-entry/address-input', done: false },
					{ name: 'Checkbox', href: '/docs/components/data-entry/checkbox', done: false },
					{ name: 'Calendar Input', href: '/docs/components/data-entry/calendar-input', done: false },
					{ name: 'Calendar Range Input', href: '/docs/components/data-entry/calendar-range-input', done: false },
					{ name: 'Color Picker', href: '/docs/components/data-entry/color-picker', done: false },
					{ name: 'Currency Input', href: '/docs/components/data-entry/currency-input', done: false },
					{ name: 'Credit Card Input', href: '/docs/components/data-entry/credit-card-input', done: false },
					{ name: 'Date Input', href: '/docs/components/data-entry/date-input', done: false },
					{ name: 'Date Picker', href: '/docs/components/data-entry/date-picker', done: false },
					{ name: 'Date Range Input', href: '/docs/components/data-entry/date-range-input', done: false },
					{ name: 'Date Range Picker', href: '/docs/components/data-entry/date-range-picker', done: false },
					{ name: 'Fieldset', href: '/docs/components/data-entry/fieldset', done: false },
					{ name: 'File Input', href: '/docs/components/data-entry/file-input', done: false },
					{ name: 'File Dropzone', href: '/docs/components/data-entry/file-dropzone', done: false },
					{ name: 'File Picker', href: '/docs/components/data-entry/file-picker', done: false },
					{ name: 'Label', href: '/docs/components/data-entry/label', done: false },
					{ name: 'Pin Input', href: '/docs/components/data-entry/pin-input', done: false },
					{ name: 'Phone Number Input', href: '/docs/components/data-entry/phone-number-input', done: false },
					{ name: 'Radio Button', href: '/docs/components/data-entry/radio-button', done: false },
					{ name: 'Radio Group', href: '/docs/components/data-entry/radio-group', done: false },
					{ name: 'Range Slider', href: '/docs/components/data-entry/range-slider', done: false },
					{ name: 'Rating', href: '/docs/components/data-entry/rating', done: false },
					{ name: 'Search Input', href: '/docs/components/data-entry/search-input', done: false },
					{ name: 'Switch', href: '/docs/components/data-entry/switch', done: false },
					{ name: 'Segmented Control', href: '/docs/components/data-entry/segmented-control', done: false },
					{ name: 'Select', href: '/docs/components/data-entry/select', done: false },
					{ name: 'Slider', href: '/docs/components/data-entry/slider', done: false },
					{ name: 'Text Input', href: '/docs/components/data-entry/text-input', done: false },
					{ name: 'Textarea', href: '/docs/components/data-entry/textarea', done: false },
					{ name: 'Toggle', href: '/docs/components/data-entry/toggle', done: false },
					{ name: 'Toggle Group', href: '/docs/components/data-entry/toggle-group', done: false },
					{ name: 'URL Input', href: '/docs/components/data-entry/url-input', done: false },
				],
			},
		],
	},
	{
		name: 'Patterns',
		href: '/docs/patterns',
		done: false,
	},
	{
		name: 'Page Sections',
		href: '/docs/page-sections',
		done: false,
	},
	{
		name: 'Page Templates',
		href: '/docs/page-templates',
		done: false,
	},
	{
		name: 'Legal',
		done: false,
		href: '/legal',
		children: [
			{ name: 'Privacy policy', href: '/legal#privacy-policy', done: false },
			{ name: 'Terms of use', href: '/legal#terms-of-use', done: false },
			{ name: 'License', href: '/legal#license', done: false },
		],
	},
	{
		name: 'Support',
		done: false,
		href: '/get-access',
		children: [
			{ name: 'Pricing', href: '/get-access', done: false },
			{ name: 'FAQ', href: '/get-access#faq', done: false },
		],
	},
]
