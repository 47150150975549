import { Outlet, useLocation } from '@remix-run/react'
import { useRef, useState } from 'react'
import { Label, Link } from 'react-aria-components'
import { Container } from '#app/components/layout/container.tsx'
import { Flex } from '#app/components/layout/flex'
import { Icon } from '#app/components/media/icon'
import { RemixBreadcrumbs } from '#app/components/navigation/remix-breadcrumbs.tsx'
import { Hr } from '#app/components/typography/hr'
import { useIsomorphicLayoutEffect } from '#app/hooks/useIsomorphicLayoutEffect.ts'
import { navigation } from '#app/navigation.ts'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '#app/old/components/disclosure/collapsible'

export const handle = {
	breadcrumb: 'Docs',
}

const DocsRoute = () => {
	return (
		<>
			<Container>
				<RemixBreadcrumbs className="mb-4 -ml-2 align-baseline" />
			</Container>
			<Container>
				<Flex justify="between" gap="10" className="w-full pt-1">
					<Sidebar className="hidden md:flex" />
					<div className="flex-1 overflow-x-hidden pb-10">
						<Outlet />
					</div>
				</Flex>
			</Container>
		</>
	)
}
export default DocsRoute

export const Sidebar = ({ className, onPress }: { className?: string; onPress?: () => void }) => {
	const sidebarNav = navigation.filter(
		({ name }) => name === 'Getting Started' || name === 'Components' /* || name === 'Patterns' || name === 'Page Sections' || name === 'Page Templates' */,
	)
	return (
		<Flex orientation="vertical" gap="2" className={className}>
			<Label className="text-xs uppercase">Docs</Label>
			<Hr className="mt-0 mb-2" />
			<Flex orientation="vertical" gap="10" className="min-w-[250px]">
				{sidebarNav.map(item => (
					<Submenu key={item.href} item={item} indentation="2" onPress={onPress ? onPress : undefined} />
				))}
			</Flex>
		</Flex>
	)
}

type Item =
	| {
			name: string
			href: string
			done: boolean
			pro?: boolean
	  }
	| {
			name: string
			href: string
			done: boolean
			pro?: boolean
			children: {
				name: string
				href: string
				done: boolean
				pro?: boolean
			}[]
	  }

const Submenu = ({ item, indentation, onPress }: { item: Item; indentation: string; onPress?: () => void }) => {
	const [isOpen, setIsOpen] = useState<boolean>(true)
	const firstRender = useRef(true)
	const location = useLocation()

	useIsomorphicLayoutEffect(() => {
		if (firstRender.current) {
			firstRender.current = false
			const preference = localStorage.getItem(item.href)
			setIsOpen(preference === null ? true : preference === 'true')
			return
		}
		localStorage.setItem(item.href, JSON.stringify(isOpen))
	}, [isOpen])

	return (
		<>
			{'children' in item ?
				<Collapsible open={isOpen} onOpenChange={setIsOpen} className={``}>
					<CollapsibleTrigger className="group -ml-2 w-full cursor-pointer rounded-md p-2">
						<Flex justify="between" items="center" gap="6">
							<span>{item.name}</span>
							<Icon name="chevron-left" className="text-muted-400 h-5 w-5 transition-transform group-data-[state=open]:-rotate-90" />
						</Flex>
					</CollapsibleTrigger>
					<CollapsibleContent className={`flex flex-col pl-${indentation} gap-2`}>
						{item.children?.map(subitem => <Submenu key={subitem.href} item={subitem} indentation={indentation} onPress={onPress} />)}
					</CollapsibleContent>
				</Collapsible>
			:	<Link
					href={item.href}
					className={`hover:bg-muted-100 text-muted-500 -ml-2 flex w-full justify-between rounded-md py-1 px-2 text-xs data-[disabled=true]:cursor-default ${location.pathname === item.href ? 'bg-muted-200' : ''}`}
					isDisabled={!item.done}
					onPress={onPress}
				>
					{item.name}{' '}
					<span className="inline-flex gap-2">
						{item.pro ?
							<span className="bg-muted-200 text-muted-400 float-right rounded-md px-2 text-xs">PRO</span>
						:	null}
						{item.done ? null : <span className="bg-muted-200 text-muted-400 float-right rounded-md px-2 text-xs">SOON</span>}{' '}
					</span>
				</Link>
			}
		</>
	)
}
